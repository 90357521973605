import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useEffect, useState } from "react";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import ogimage from "../assets/Images/ogImages/OG_Thank_you-ClientStory.png";
import BannerImage from "../assets/Images/ThankYou/Thankyou_clientstory_banner.png";
import Layout from "../components/layouts/layout";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
import Megaview from "../templates/MegaView";
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import VideoBanner from "../templates/VideoBanner";

const ThankyouClientStory = () => {
  const [page, setPage] = useState(1);
  const [pdf, setPdf] = useState("");
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const urlParams =
    typeof window !== `undefined` &&
    new URLSearchParams(window.location.search);
  const myParam = typeof window !== `undefined` && urlParams.get("title");

  useEffect(() => {
    setPdf(localStorage.getItem("pdf_file"));
    DataService.getData(
      "/admin/api/post?limit=1&page=1&page_id=1&type=White Paper"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/post?limit=${3}&page=${page}&page_id=1&type=Client Story`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  /**
   * Video Banner
   */

  const VideobannerData = {
    variant: "contained",
    color: "primary",
    label: "DOWNLOAD NOW",
    ImageBannerTitle: "Thank You!",
    ImageBannerSubTitle: (
      <>
        for downloading "{myParam}". We hope you enjoy reading our client story.
        <div className="banner-margin-top">
          If you can’t wait to check your email
        </div>
      </>
    ),
    BannerImage: BannerImage,
    route: pdf,
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  return (
    <Layout
      seo={{
        ogimage: ogimage,
        title: "Thank You",
        ogtitle: "Thank You",
        description: "Welcome to Digiryte Thank You Page",
        ogurl: `${BASE_SITE_URL}/thank-you-client-story`,
      }}
    >
      <GatsbySeo noindex={true} />
      <VideoBanner
        VideobannerData={VideobannerData}
        wrapClassName="custom-banner-wrap"
      />
      <div className="mt-70 body-container">
        <div className="dynamic-title-header ttc">
          {" "}
          <span className="red-line3letter">Rec</span>ommended White paper
        </div>
        <div className="mt-50"></div>
        <Megaview MegaviewData={megaviewData.data} />
      </div>
      <div className="mt-70">
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default ThankyouClientStory;
